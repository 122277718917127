<template>
  <div>
    <b-overlay :show="loading">
    <b-card>
      <b-card-body>
        <div class="col-lg-12">
          <div class="ibox float-e-margins">
            <div class="ibox-title">
              <div class="row">
                <div class="col-md-8">
                  <h4 class="subtitle is-5">
                    Detalles de Comisión
                  </h4>
                </div>
                <div class="col-md-4">

                  <b-button
                    variant="outline-primary"
                    class="mr-2"
                    disabled
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="mr-50"
                    />
                    Descargar
                  </b-button>

                  <b-button
                    variant="outline-primary"
                    @click="goList()"
                  >
                    <feather-icon
                      icon="ArrowLeftIcon"
                      class="mr-50"
                    />
                    Volver
                  </b-button>

                </div>
              </div>
            </div>
            <hr>
            <div class="ibox-content">
              <div class="row mt-3">
                <div class="col-md-4">
                  <b> Desde:</b> {{ comisionSeleccionada.fecha_inicio }}<br>
                  <b> Hasta:</b> {{ comisionSeleccionada.fecha_fin }}
                </div>
                <div class="col-md-4">
                  <b> Usuario:</b> {{comisionSeleccionada.usuario ? comisionSeleccionada.usuario.nombre_usuario: '' }}
                </div>
                <div class="col-md-4">
                  <b >Grupo:</b> {{ comisionSeleccionada.usuario && comisionSeleccionada.usuario.grupos[0] ? comisionSeleccionada.usuario.grupos[0].nombre_grupo_comercial : 'N/A' }}
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-md-4">
                  <b>Total ventas: </b>{{ moneyFormat(comisionSeleccionada.total_venta) }}
                </div>
                <div class="col-md-4">
                  <b>Anticipo:</b> {{ moneyFormat(comisionSeleccionada.total_comision_anticipo) }}
                </div>
                <div class="col-md-4">
                  <b>Total comisión:</b> {{ moneyFormat(comisionSeleccionada.total_comision) }}
                </div>
              </div>
              <div class="mt-3">
                <h5>
                  Listado de fichas:
                </h5>
              </div>

              <div>
                <b-table
                  striped
                  hover
                  small
                  :per-page="perPage"
                  :items="items"
                  :fields="fields"
                  responsive
                  class="mb-0"
                  show-empty
                >
                  <template #cell(ficha)="data">
                    {{ data.item.ficha }}
                  </template>
                  <template #cell(totalAlumnos)="data">
                    {{ data.item.totalAlumnos }}
                  </template>
                  <template #cell(descuentoProducto)="data">
                    {{ data.item.descuentoProducto }}
                  </template>
                  <template #cell(comision)="data">
                    {{ data.item.comision }}
                  </template>
                  <template #cell(propia)="data">
                    {{ data.item.propia }}
                  </template>
                  <template #empty="scope">
                    <div class="text-center">
                      No existen resultados
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </b-card-body>
    </b-card>
    </b-overlay>
  </div>
</template>

<script>
export default {
  name: 'DetalleComision',
  data() {
    return {
      page: 1,
      perPage: 50,
      totalRows: 1,
      idComision: 0,
      fields: [
        { key: 'ficha', label: 'Ficha' },
        { key: 'totalAlumnos', label: 'Total Alumnos' },
        { key: 'descuentoProducto', label: 'Descuento producto' },
        { key: 'comision', label: 'Comisión' },
        { key: 'propia', label: 'Venta propia' },
      ],
    }
  },
  computed: {
    items() {
      let detalles = []
      if (this.$store.state.comisiones.detalleComision.detalles){
        detalles = this.$store.state.comisiones.detalleComision.detalles.map(item => ({
          ficha: item.num_ficha,
          totalAlumnos: item.total_alumnos,
          descuentoProducto: item.descuento_producto,
          comision: item.comision,
          propia: item.propia === '0' ? 'No' : 'Si',
        }

      ))
      }
      return detalles
    },
    comisionSeleccionada() {
      return this.$store.state.comisiones.detalleComision
    },
    loading() {
      return this.$store.state.comisiones.loading
    },
  },
  mounted() {
    this.getDetalleComisionmision()
  },
  methods: {
    moneyFormat(value) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })
      return formatter.format(value)
    },
    getDetalleComisionmision() {
      const filterData = {
        page: this.page,
        perPage: this.perPage,
        id: this.$route.params.id
      }
      this.$store.dispatch('comisiones/getDetalleComision', filterData)
    },
    goList() {
      this.$router.push('/comisiones')
    },
  },
}
</script>

<style scoped>

</style>
